import React, { CSSProperties } from "react";

import { Button } from "semantic-ui-react";

import { NotificationItem } from "../common/NotificationContainer";

import { formatDatetime } from "react-lib/utils/dateUtils";

export type BillLockNotiMessageItem = NotificationItem &
  Partial<{
    datetime: string;
    division_name: string;
    encounter: number;
    encounter_number: string;
    patient_hn: string;
    patient_name: string;
    user_name: string;
  }>;

type SubBillLockNotiMessageProps = BillLockNotiMessageItem & {
  disabled?: boolean;
  style?: CSSProperties;
  onOpenPendingInvoice: () => void;
};

const SubBillLockNotiMessage = (props: SubBillLockNotiMessageProps) => (
  <div
    style={{
      backgroundColor: "rgba(255, 232, 185, 0.74)",
      display: "flex",
      margin: "-14px -28px -14px -14px",
      position: "relative",
      ...props.style,
    }}
  >
    <div style={{ lineHeight: 2, padding: "0.75rem 4rem 0.75rem 1.5rem" }}>
      <div style={{ display: "flex" }}>
        <div style={{ color: "rgba(9, 94, 157, 1)" }}>
          คำร้องขอ ปลดล็อครายการค่าใช้จ่าย สำหรับสั่ง Order
        </div>
        <div style={{ color: "rgba(100, 98, 98, 1)", marginLeft: "3rem" }}>
          {formatDatetime(props.datetime)}
        </div>
      </div>
      <div style={{ color: "rgba(0,0,0,.87)", display: "flex" }}>
        <strong>HN :{"\u00A0"}</strong>
        <span>{props.patient_hn}</span>
        <strong style={{ marginLeft: "3rem" }}>EN.{"\u00A0"}</strong>
        <span>{props.encounter_number}</span>
        <strong style={{ marginLeft: "3rem" }}>ผู้ป่วย :{"\u00A0"}</strong>
        <span>{props.patient_name}</span>
      </div>
      <div style={{ color: "rgba(0,0,0,.87)", display: "flex", minWidth: "max-content" }}>
        <strong>ร้องขอจาก หน่วยงาน/ผู้ร้องขอ :{"\u00A0"}</strong>
        <span>
          {props.division_name} / {props.user_name}
        </span>
      </div>
      <div style={{ float: "right", margin: "0.5rem -3rem 0 0" }}>
        <Button
          color="blue"
          disabled={props.disabled}
          size="mini"
          onClick={props.onOpenPendingInvoice}
        >
          เปิดหน้ารายการรอรับชำระ
        </Button>
      </div>
    </div>
  </div>
);

SubBillLockNotiMessage.displayName = "SubBillLockNotiMessage";

export default React.memo(SubBillLockNotiMessage);
